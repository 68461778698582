"use client";

import {Button, Icon, Stack} from "@chakra-ui/react";
import {GoArrowRight} from "@onemind-services-llc/react-icons-ng/go";

type HeroButtonProps = {
  Pricing?: boolean;
};
export const HeroButtons = ({Pricing = false}: HeroButtonProps) => {
  return (
    <Stack direction={{base: "column", md: "row"}} spacing={4} textAlign={"center"}>
      <Button
        bg={"#007FFF"}
        borderColor="#4299e1"
        borderWidth={"1px"}
        fontSize={"lg"}
        color={"white"}
        _hover={{backgroundColor: "#0056B3"}}
        as={"a"}
        href={"#contact-form"}
        minW={"160px"}
        minH={"45px"}
      >
        Contact Us {!Pricing && <Icon as={GoArrowRight} ml={2} />}
      </Button>
      {Pricing && (
        <Button
          style={{
            background: Pricing ? "white" : "#007FFF",
            color: Pricing ? "#007FFF" : "white",
          }}
          fontSize={"lg"}
          as={"a"}
          href={"#section-pricing"}
          minW={"160px"}
          minH={"50px"}
          _hover={{
            textDecoration: Pricing ? "underline" : "none",
          }}
        >
          View Pricing
          <Icon as={GoArrowRight} ml={2} />
        </Button>
      )}
    </Stack>
  );
};
