"use client";
import Image, {StaticImageData} from "next/image";
import React from "react";
import {TbAwardFilled} from "@onemind-services-llc/react-icons-ng/tb";
import {
  Flex,
  Heading,
  ListItem,
  Stack,
  Text,
  UnorderedList,
  Box,
  AspectRatio,
} from "@chakra-ui/react";

type HeroProps = {
  title: string;
  description: string[];
  image?: StaticImageData;
  imageAlt?: string;
  points?: Array<string>;
  children?: React.ReactNode;
  videoSrc?: string;
  premiumPartner?: boolean;
};
export const Hero = ({
  title,
  description = [],
  image,
  imageAlt = "",
  points,
  children,
  videoSrc,
  premiumPartner = false,
}: HeroProps) => {
  return (
    <Stack direction={{base: "column", md: "row"}} p={8} pb={0}>
      <Flex flex={1}>
        <Stack spacing={6} w={"full"}>
          <Heading
            as={"h1"}
            fontSize={{base: "3xl", md: "4xl", lg: "5xl"}}
            data-cy="section-header"
          >
            <Text as={"span"} position={"relative"}>
              {title}
            </Text>
          </Heading>
          <Box data-cy="section-intro">
            {description.map((item, idx) => {
              return (
                <Text
                  key={idx}
                  fontSize={{base: "md", lg: "lg"}}
                  color={"gray.800"}
                  margin={`${idx === 0 ? 0 : 0.75}rem 0 ${
                    idx === description.length - 1 ? 0 : 0.75
                  }rem`}
                >
                  {item}
                </Text>
              );
            })}
          </Box>

          {points && (
            <UnorderedList spacing={2} mb={0}>
              {points.map((point, index) => (
                <ListItem
                  key={index}
                  fontSize={{base: "md", lg: "lg"}}
                  color={"gray.800"}
                >
                  {point}
                </ListItem>
              ))}
            </UnorderedList>
          )}
          {children}
        </Stack>
      </Flex>
      <Flex
        flex={1}
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        position={"relative"}
      >
        {premiumPartner && (
          <Box
            bg="pink.500"
            borderRadius={"8px"}
            borderColor={"pink.500"}
            borderWidth={"1px"}
            color="white"
            // width={"180px"}
            p={2}
            textAlign="center"
            lineHeight="1.5"
          >
            <Flex
              direction={"row"}
              gap="4px"
              justifyContent={"center"}
              alignItems={"center"}
            >
              PREMIUM PARTNER
              <TbAwardFilled />
            </Flex>
          </Box>
        )}
        <AspectRatio
          position={"relative"}
          ratio={10 / 7}
          width={{base: "full", sm: "300px", md: "400px", lg: "max(30vw, 420px)"}}
        >
          {videoSrc ? (
            <video
              width="450px"
              height="360px"
              autoPlay
              muted
              loop
              data-cy="intro-banner"
            >
              <source src={videoSrc} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            image && (
              <Image
                src={image}
                alt={imageAlt}
                priority
                fill
                style={{objectFit: "contain", objectPosition: "center"}}
                data-cy={"intro-banner"}
              />
            )
          )}
        </AspectRatio>
      </Flex>
    </Stack>
  );
};
